<template>
  <div class="h-md bg-charcoal-default px-xs flex items-center" data-fs="vatSwitch" data-test-id="vatSwitch">
    <Stack class="w-full" justify="end" align="center">
      <Switch
        id="vat-switch"
        :model-value="isVatIncluded"
        :value="isVatIncluded"
        name="vat-switch"
        label="VAT"
        @update:model-value="toggleIsVatIncluded"
        data-test-id="vatToggle"
        is-vat
        label-visible
        class="!justify-end !align-center"
        data-fs="vatSwitchInput"
      />
    </Stack>
  </div>
</template>
<script setup lang="ts">
const { isVatIncluded, toggleIsVatIncluded } = useUIState();
</script>
