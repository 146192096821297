/**
 * Remove any spacing for string content
 * If there is no content return empty string
 * If there is string content return content whilst removing any whitespace
 * If an error is found return an empty string
 */

export const removeSpacing = (content: string): string => {
  if (!content) {
    return '';
  }
  return content.replace(/\s+/g, '');
};
