<template>
  <div class="flex-1 hidden md:block relative" :class="overlay && !mobileSearch && !searchResults ? 'z-10' : 'z-50'">
    <form @submit.prevent="handleSearchSubmit" v-memo="[search]">
      <Input
        @search:input-value="handleSearchSubmit"
        :model-value="search"
        placeholder="What are you looking for?"
        @update:input-value="(val) => handleDebouncedSearch(String(val))"
        label="Search Products"
        :label-visible="false"
        id="product-search"
        data-test-id="searchInput"
        size="lg"
        :is-search="true"
        :search-classes="hoverInputClasses"
        @keydown.enter="handleSearchSubmit"
        data-fs="desktopHeaderSearch"
      />
    </form>

    <HeaderSearchResults
      :suggestions="suggestions"
      :items="items"
      :loading="isLoading"
      :query="query"
      @update:query="(val) => handleDebouncedSearch(String(val))"
      v-memo="[suggestions, items, isLoading, query]"
    />
  </div>
  <Transition name="fade">
    <Stack
      v-if="mobileSearch"
      v-memo="[mobileSearch]"
      tag="div"
      align="center"
      justify="start"
      gap="xs"
      class="w-full md:!hidden fixed top-none left-none z-30 py-xs pr-xs pl-2xs bg-white"
    >
      <button @click="setMobileSearch(false)" tabindex="0">
        <Icon name="arrow-left" :size="16" class="w-6 h-6" />
        <span class="sr-only">Close Search</span>
      </button>

      <ClientOnly>
        <div class="flex-1">
          <form @submit.prevent>
            <Input
              ref="mobileSearchInput"
              tabindex="1"
              :model-value="search"
              @update:input-value="(val) => handleDebouncedSearch(String(val))"
              @update:close-input="setMobileSearch(false)"
              @keydown.esc="setMobileSearch(false)"
              class="hover:!ring-mkm-yellow-default hover:!ring-0 focus:!ring-0 !my-none"
              placeholder="What are you looking for?"
              label="Search Products"
              :label-visible="false"
              id="mobile-product-search"
              data-test-id="searchInput"
              :is-search="true"
              @keydown.enter="handleSearchSubmit"
              data-fs="mobileHeaderSearch"
            />
          </form>
        </div>
      </ClientOnly>

      <Transition name="fade">
        <HeaderSearchResults
          v-if="searchResults"
          :suggestions="suggestions"
          :items="items"
          :loading="isLoading"
          :query="query"
          v-memo="[suggestions, items, isLoading, query]"
        />
      </Transition>
    </Stack>
  </Transition>
</template>

<script lang="ts" setup>
const router = useRouter();

const search = shallowRef("");
const query = shallowRef("");
const isLoading = shallowRef(false);
const mobileSearchInput = ref<HTMLInputElement | null>(null);
let abortController: AbortController | null = null;
let searchTimeout: ReturnType<typeof setTimeout> | null = null;

const { mobileSearch, setMobileSearch, setSearchResults, setOverlay, overlay, searchResults } = useUIState();
const { suggestions, items, getSuggestions } = useSuggestions();
const { fetchPrice } = usePrices();

const hoverInputClasses = computed(() => {
  return overlay.value
    ? "hover:ring-[6px] hover:!ring-[#6B6000] focus:border-mkm-blue-dark focus:ring-[6px] focus:ring-[#6B6000]"
    : "hover:ring-[6px] hover:ring-[#D6C000] focus:border-mkm-blue-dark focus:ring-[6px] focus:!ring-[#D6C000]";
});

watch(mobileSearch, async (newValue) => {
  if (newValue && process.client) {
    await nextTick();
    mobileSearchInput.value?.focus();
  }
});

const searchBloomreach = async (searchTerm: string): Promise<void> => {
  if (!searchTerm || searchTerm.length < 2) return;

  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  try {
    await getSuggestions(searchTerm, { signal: abortController.signal });
    if (!suggestions.value?.length) {
      setSearchResults(false);
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      return;
    }
  } finally {
    if (!abortController?.signal.aborted) {
      isLoading.value = false;
    }
  }
};

const handleDebouncedSearch = (searchTerm: string): void => {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  query.value = searchTerm;
  search.value = searchTerm;

  if (searchTerm.length > 2) {
    isLoading.value = true;
    setSearchResults(true);
    setOverlay(true);

    searchTimeout = setTimeout(() => {
      if (process.client) {
        requestAnimationFrame(() => {
          searchBloomreach(searchTerm);
        });
      }
    }, 300);
  } else {
    isLoading.value = false;
    setSearchResults(false);
    items.value = [];
    suggestions.value = [];
  }
};

const handleSearchSubmit = async () => {
  if (!search.value) return;

  setSearchResults(false);
  setMobileSearch(false);

  await router.push({
    path: "/search",
    query: {
      q: search.value,
    },
  });
};

const priceRequestQueue = new Set<string>();
let batchTimeout: NodeJS.Timeout | null = null;
const BATCH_DELAY = 50; // ms

watch(
  items,
  (newItems) => {
    if (!newItems?.length) return;

    newItems.forEach((item) => {
      priceRequestQueue.add(item.itemId.id);
    });

    if (batchTimeout) {
      clearTimeout(batchTimeout);
    }

    batchTimeout = setTimeout(async () => {
      if (priceRequestQueue.size > 0) {
        const batchIds = Array.from(priceRequestQueue).join(",");
        await fetchPrice(batchIds);
        priceRequestQueue.clear();
      }
    }, BATCH_DELAY);
  },
  { deep: false },
);

onMounted(() => {
  if (mobileSearch.value && process.client) {
    nextTick(() => {
      mobileSearchInput.value?.focus();
    });
  }
});

onUnmounted(() => {
  if (searchTimeout) clearTimeout(searchTimeout);
  if (batchTimeout) clearTimeout(batchTimeout);
  if (abortController) abortController.abort();
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
