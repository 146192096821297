<template>
  <Stack tag="div" justify="start" gap="xs" align="center" :breakpoints="{ gap: { md: 'sm' } }">
    <HeaderLink
      icon="menu"
      label="Menu"
      @click="setNavigation()"
      border
      data-test-id="menuButton"
      data-fs="menuHeaderButton"
    />
    <NuxtLink to="/" class="w-2xl xs:w-3xl md:w-[129px]" no-prefetch>
      <SvgLogo name="mkm-blue" class-name="w-2xl xs:w-3xl md:w-[129px]" data-test-id="logo" />
      <p class="sr-only">MKM Home Page</p>
    </NuxtLink>

    <Stack tag="div" class="!hidden lg:!flex" direction="col" gap="none">
      <Tooltip
        ref="tooltipElement"
        axis="left"
        position="bottom"
        :state="autoBranchSelect"
        :is-closeable="true"
        :is-hoverable="false"
        @tooltip:close="setAutoBranchSelect(false)"
      >
        <template #content>
          <Text size="sm" weight="semi" class="min-w-[280px]">
            <span class="inline">
              We think this is your nearest branch. If not,
              <button class="inline whitespace-nowrap appearance-none text-mkm-blue-light" @click="handleBranchSelect">
                choose one you think is nearer
              </button>
              to see your local price and delivery options.
            </span>
          </Text>
        </template>
        <button
          @click="handleBranchSelect"
          class="text-left"
          data-test-id="selectBranchDesktop"
          data-fs="headerSelectBranchBtn"
        >
          <Text size="caption" tag="span" uppercase weight="semibold" class="w-unset">{{ branchState.message }}</Text>
          <Stack tag="div" gap="none" align="center">
            <Text size="caption" tag="span" weight="bold" class="text-nowrap w-unset" data-test-id="branchName">
              {{ branchState.branch }}
            </Text>
            <Icon name="triangle-down" :size="16" v-if="selectedBranch" />
            <Text size="caption" weight="semi" class="ml-3xs" v-if="selectedBranch">{{ isStoreOpenText }}</Text>
          </Stack>
        </button>
      </Tooltip>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { Branch } from "mkm-types";

const {
  setNavigation,
  navigation,
  branchTray,
  setBranchTray,
  setAutoBranchSelect,
  autoBranchSelect,
  basketTray,
  setBasketTray,
} = useUIState();
const { selectedBranch } = useBranches();

const isStoreOpenText = ref<string>("");
const tooltipElement = ref<HTMLElement | null>(null);

const branchState = computed(() => {
  return {
    branch: selectedBranch.value ? selectedBranch.value.name : "Please select a branch",
    message: selectedBranch.value ? "Your Branch Is" : "No Branch Selected",
  };
});

const handleBranchSelect = () => {
  setAutoBranchSelect(false);
  setBranchTray();
};

const updateWorkTimes = (branch: Branch) => {
  const useWT = useBranchWorkingTime(branch);

  switch (useWT.openStatus.value) {
    case useWT.openStatuses.value.OPENING_SOON:
      isStoreOpenText.value = `Opens today at ${useWT?.workingTimeToday?.value?.opens_at}`;
      break;

    case useWT.openStatuses.value.OPEN:
      isStoreOpenText.value = `Open until ${useWT?.workingTimeToday?.value?.closes_at}`;
      break;

    case useWT.openStatuses.value.CLOSED:
      if (useWT.nextWorkingDayIsTomorrow.value) {
        // @ts-ignore
        isStoreOpenText.value = `Opens tomorrow at ${useWT?.nextWorkingDayWorkingTime?.value?.opens_at}`;
      } else if (useWT.nextWorkingDayWorkingTime.value === undefined) {
        isStoreOpenText.value = "Closed today";
      } else {
        // @ts-ignore
        isStoreOpenText.value = `Opens on ${useWT?.nextWorkingDayWorkingTime?.value?.dayOfWeek} at ${useWT?.nextWorkingDayWorkingTime?.value?.opens_at}`;
      }
      break;

    case useWT.openStatuses.value.CLOSING_SOON:
      isStoreOpenText.value = `Closing soon at ${useWT?.workingTimeToday?.value?.closes_at}`;
      break;
  }
};

watch(
  selectedBranch,
  (branch) => {
    if (branch && process.client) {
      updateWorkTimes(branch);
    }
  },
  { immediate: true },
);

watch(autoBranchSelect, (value) => {
  if (value) {
    if (tooltipElement.value) window.scrollTo({ top: 0, behavior: "smooth" });

    if (navigation.value) setNavigation();
    if (branchTray.value) setBranchTray();
    if (basketTray.value) setBasketTray();
  }
});
</script>
