<template>
  <Stack
    v-show="searchResults"
    tag="div"
    direction="col"
    col="md"
    class="absolute bottom-[-545px] left-none bg-white w-full h-[545px] md:bottom-[-550px] z-50 rounded-b-sm sm:rounded-sm shadow-sm py-xs overflow-y-scroll contain-layout contain-paint"
  >
    <Stack
      tag="ul"
      direction="col"
      gap="3xs"
      class="px-sm w-full relative"
      data-test-id="searchSuggestionsContainer"
      data-fs="searchSuggestions"
    >
      <Stack
        v-if="suggestions"
        tag="li"
        justify="between"
        align="center"
        v-for="(suggestionTerm, index) in suggestions"
        :key="`${suggestionTerm}:${index}`"
        class="w-full text-grey-default"
        data-fs="searchTextResults"
      >
        <NuxtLink
          :to="`/search?q=${suggestionTerm.terms}`"
          @click="closeSearchResultsUponClick"
          weight="semi"
          class="text-charcoal-light"
          data-test-id="suggestedQueries"
          v-html="highlight(String(suggestionTerm.terms), query)"
        />

        <button @click="handleUpdateQuery(suggestionTerm)">
          <span class="sr-only">{{ suggestionTerm.terms }}</span>
          <Icon name="arrow-top-left" :size="14" filled />
        </button>
      </Stack>
    </Stack>

    <Stack tag="div" direction="col" gap="none" class="w-full">
      <Text class="px-sm pb-sm" weight="bold" v-if="items && items.length > 0">
        These products may be of interest
      </Text>

      <Stack tag="ul" direction="col" gap="none" class="w-full">
        <li class="w-full" data-fs="searchCard" v-for="(item, index) in items" :key="`${item.itemId}:${index}`">
          <NuxtLink :to="generateProductUrl(item.displayName, item.itemId.id)" @click="closeSearchResultsUponClick">
            <SearchCard
              :content="{
                image: item.imageSet.original.link.href ?? IMAGES.PLACEHOLDER,
                title: item.displayName,
                subtitle: `Product code: ${item.itemId.id}`,
                priceEach: 1,
                quantity: 1,
                total: getItemPrice(item),
                vat: isVatIncluded,
                hasVat: isVatIncluded,
                unit: '',
              }"
              data-test-id="searchSuggestion"
            />
          </NuxtLink>
        </li>
      </Stack>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import type { HeaderSearchResultsProps, PriceResult } from "./headerTypes";
import { IMAGES } from "@/constants/images";
import slugify from "slugify";

const props = defineProps<HeaderSearchResultsProps>();

const { items, query, suggestions } = toRefs(props);

const emit = defineEmits<{
  (event: "update:query", value: string): void;
}>();

const { searchResults, setSearchResults, isVatIncluded, setMobileSearch } = useUIState();
const { findPriceObjById } = usePrices();
const { selectedBranch } = useBranches();
const { user } = useUser();

const priceCache = new Map<string, PriceResult>();
const highlightCache = new Map<string, string>();

const handleUpdateQuery = (suggestionTerm: Suggestions) => {
  if (suggestionTerm.terms) {
    emit("update:query", suggestionTerm.terms);
  }
};

const updatePrice = computed(() => {
  return (item: Item): PriceResult => {
    const cacheKey = item.itemId.id;
    const cached = priceCache.get(cacheKey);
    if (cached) {
      return cached;
    }

    if (!selectedBranch) {
      return { price: undefined, price_inc_vat: undefined };
    }

    let result: PriceResult;

    if (user.value) {
      const priceObj = findPriceObjById(cacheKey);
      result = {
        price: priceObj?.price?.toString(),
        price_inc_vat: priceObj?.price_inc_vat?.toString(),
      };
    } else {
      result = item.customAttrs?.reduce<PriceResult>(
        (acc, { name, values }) => {
          if (name === "price" || name === "price_inc_vat") {
            acc[name as keyof PriceResult] = values[0];
          }
          return acc;
        },
        { price: undefined, price_inc_vat: undefined },
      ) ?? { price: undefined, price_inc_vat: undefined };
    }

    if (priceCache.size > 100) {
      priceCache.clear();
    }
    priceCache.set(cacheKey, result);
    return result;
  };
});

const getItemPrice = (item: Item): number => {
  try {
    const priceResult = updatePrice.value(item);
    const priceStr = isVatIncluded ? priceResult?.price_inc_vat : priceResult?.price;
    if (!priceStr) return 0;
    const price = Number.parseFloat(priceStr);
    return Number.isNaN(price) ? 0 : price;
  } catch {
    return 0;
  }
};

const closeSearchResultsUponClick = () => {
  setSearchResults(false);
  setMobileSearch(false);
};

const generateProductUrl = (displayName: string, sku: string) =>
  `/product/${slugify(displayName, {
    lower: true,
  })}-${sku}`;

const highlight = computed(() => {
  return (words: string, query: string): string => {
    const key = `${words}:${query}`;
    const cached = highlightCache.get(key);
    if (cached) {
      return cached;
    }

    const lowerCaseWords = words.toLowerCase();
    const lowerCaseQuery = query.toLowerCase();
    const regex = new RegExp(lowerCaseQuery, "gi");
    const result = lowerCaseWords.replace(
      regex,
      `<strong class="font-bold text-charcoal-default">${lowerCaseQuery}</strong>`,
    );

    if (highlightCache.size > 100) {
      highlightCache.clear();
    }
    highlightCache.set(key, result);
    return result;
  };
});

onUnmounted(() => {
  priceCache.clear();
  highlightCache.clear();
});
</script>
