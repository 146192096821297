<template>
  <Stack
    tag="div"
    class="h-[48px] bg-mkm-blue-default px-xs text-white relative flex lg:!hidden"
    align="center"
    justify="between"
    :breakpoints="{ justify: { sm: 'start' } }"
  >
    <Text size="caption" tag="span" weight="semibold" uppercase class="w-unset lg:!hidden">{{
      branchState.message
    }}</Text>
    <Tooltip
      position="bottom"
      :state="autoBranchSelect"
      :is-closeable="true"
      :is-hoverable="false"
      axis="right"
      :breakpoints="{ axis: { sm: 'left' } }"
      class="lg:!hidden"
      classes="tooltip-content"
      @tooltip:close="setAutoBranchSelect(false)"
    >
      <template #content>
        <Text size="sm" weight="semi" class=""
          ><span class="inline"
            >We think this is your nearest branch. If not,
            <button
              data-test-id="chooseLocalBranch"
              class="inline whitespace-nowrap appearance-none text-mkm-blue-light"
              @click="handleBranchSelect"
            >
              choose one you think is nearer
            </button>
            to see your local price and delivery options.</span
          ></Text
        >
      </template>
      <button
        data-test-id="selectBranchMobile"
        data-fs="selectBranchMobile"
        @click="handleBranchSelect"
        class="flex items-center py-2xs"
      >
        <Text
          size="caption"
          tag="span"
          weight="bold"
          class="w-unset lg:!hidden sm:!text-sm mr-3xs text-nowrap"
          data-test-id="branchState"
        >
          {{ branchState.branch }}
        </Text>
        <Icon name="triangle-down" :size="16" v-if="selectedBranch" class="hidden sm:inline-block sm:mr-3xs" />
        <Text tag="span" size="caption" class="!hidden sm:!block sm:!text-sm" data-test-id="openHours">
          {{ isStoreOpenText }}
        </Text>
      </button>
    </Tooltip>
  </Stack>

  <br-component component="yourMkmGuestMenu" />
</template>

<script lang="ts" setup>
import { Branch } from "mkm-types";

const { selectedBranch } = useBranches();
const { setBranchTray, autoBranchSelect, setAutoBranchSelect } = useUIState();

const isStoreOpenText = ref<string>("");

const handleBranchSelect = () => {
  setAutoBranchSelect(false);
  setBranchTray();
};

const branchState = computed(() => {
  return {
    branch: selectedBranch.value ? selectedBranch.value.name : "Please select a branch",
    message: selectedBranch.value ? "Your Branch Is" : "No Branch Selected",
  };
});

const updateWorkTimes = (branch: Branch) => {
  const useWT = useBranchWorkingTime(branch);

  switch (useWT.openStatus.value) {
    case useWT.openStatuses.value.OPENING_SOON:
      isStoreOpenText.value = `Opens today at ${useWT?.workingTimeToday?.value?.opens_at}`;
      break;

    case useWT.openStatuses.value.OPEN:
      isStoreOpenText.value = `Open until ${useWT?.workingTimeToday?.value?.closes_at}`;
      break;

    case useWT.openStatuses.value.CLOSED:
      if (useWT.nextWorkingDayIsTomorrow.value) {
        // @ts-ignore
        isStoreOpenText.value = `Opens tomorrow at ${useWT?.nextWorkingDayWorkingTime?.value?.opens_at}`;
      } else if (useWT.nextWorkingDayWorkingTime.value === undefined) {
        isStoreOpenText.value = "Closed today";
      } else {
        // @ts-ignore
        isStoreOpenText.value = `Opens on ${useWT?.nextWorkingDayWorkingTime?.value?.dayOfWeek} at ${useWT?.nextWorkingDayWorkingTime?.value?.opens_at}`;
      }
      break;

    case useWT.openStatuses.value.CLOSING_SOON:
      isStoreOpenText.value = `Closing soon at ${useWT?.workingTimeToday?.value?.closes_at}`;
      break;
  }
};

watch(
  selectedBranch,
  (branch) => {
    if (branch && process.client) {
      updateWorkTimes(branch);
    }
  },
  { immediate: true },
);
</script>

<style>
.tooltip-content {
  right: 0px;
}

@media (min-width: 768px) {
  .tooltip-content {
    left: 0px;
  }
}
</style>
