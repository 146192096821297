import { sanitizeSearchQuery } from "mkm-avengers";

interface Attributes {
  name: string;
  values: string[];
}

export interface Item {
  displayName: string;
  slug: string;
  imageSet: {
    original: {
      link: {
        href: string;
      };
    };
  };
  itemId: {
    id: string;
  };
  customAttrs?: Attributes[];
}

export interface Suggestions {
  terms: string;
}

// Matches Bloomreach SDK type structure
interface SuggestionResult {
  terms?: Array<string | null>;
  items?: Array<Item | null>;
  timestamp?: number;
}

export interface UseSuggestionsState {
  suggestions: Suggestions[];
  items: Item[];
}

const suggestionsCache = new Map<string, SuggestionResult>();
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes cache TTL

const clearCache = () => {
  const now = Date.now();
  for (const [key, value] of suggestionsCache.entries()) {
    if (value.timestamp && now - value.timestamp > CACHE_TTL) {
      suggestionsCache.delete(key);
    }
  }
};

const processSuggestionTerms = (terms: Array<string | null> | undefined): Suggestions[] => {
  return (
    terms
      ?.map((term: string | null) => {
        if (term === null) return null;
        return { terms: term };
      })
      .filter((item): item is Suggestions => item !== null)
      .slice(0, 6) ?? []
  );
};

const filterAvailableItems = (items: Item[]): Item[] => {
  return items.filter((item: Item) => {
    const customAttrs = item.customAttrs?.find((attr: Attributes) => attr.name === "availability_flag");
    return customAttrs?.values?.some((value: string) => ["1", "2", "4"].includes(value));
  });
};

const useSuggestions = () => {
  const { selectedBranchId } = useBranches();
  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));
  const catalogViews = computed(() => `mkmbs:${viewId.value}`);
  const { BR_ENV_TYPE } = useRuntimeConfig().public;

  const state = useState<UseSuggestionsState>(`useSuggestions`, () => ({
    suggestions: [] as Suggestions[],
    items: [] as Item[],
  }));

  const processCache = (cachedResult: SuggestionResult) => {
    state.value.suggestions = processSuggestionTerms(cachedResult.terms);
    state.value.items = cachedResult.items?.filter((item): item is Item => item !== null) ?? [];
  };

  const getSuggestions = async (text: string, options?: { signal?: AbortSignal }) => {
    if (!text || text.length < 2) {
      state.value.suggestions = [];
      state.value.items = [];
      return;
    }

    const cacheKey = `${text}-${viewId.value}-${BR_ENV_TYPE}`;

    const cachedResult = suggestionsCache.get(cacheKey);
    if (cachedResult?.timestamp && Date.now() - cachedResult.timestamp < CACHE_TTL) {
      processCache(cachedResult);
      return;
    }

    const { data: keywordData, error } = await useAsyncData(
      `suggestions-${text}`,
      async () => {
        const result = await useSdk().brSearch.findSuggestions({
          queryName: "getSuggestion",
          text: sanitizeSearchQuery(text),
          queryHint: {
            viewId: viewId.value,
            brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
            brUid2: brUid.value ?? undefined,
            catalogViews: catalogViews.value,
            facetFieldFilters: [{ id: "availability_flag", values: ["1", "2", "4"] }],
          },
          // Do not re-format this, BR API expects this format on one line.
          expect: `terms, items { displayName, slug, imageSet { original { link { href } } }, customAttrs { name, values } itemId { id } }`,
        });

        return {
          terms: result.terms?.filter((term: string | null): term is string => term !== null) ?? [],
          items: (result.items?.filter((item: object | null) => item !== null) as Item[]) ?? [],
          timestamp: Date.now(),
        } satisfies SuggestionResult;
      },
      {
        server: false,
        lazy: true,
        watch: [],
        default: () => ({ items: [], terms: [], timestamp: Date.now() }),
        ...(options?.signal ? { signal: options.signal } : {}),
      },
    );

    useHandleError(error.value);

    if (keywordData.value) {
      suggestionsCache.set(cacheKey, keywordData.value);

      state.value.suggestions = processSuggestionTerms(keywordData.value.terms);
      state.value.items = filterAvailableItems((keywordData.value.items as Item[]) ?? []);
    }

    clearCache();
  };

  return {
    getSuggestions,
    ...toRefs(state.value),
  };
};

export default useSuggestions;
