<template>
  <div :data-content="`${component} ${page}`">
    <br-component component="shopByRoomMenu" />

    <VATSwitch />
    <Stack
      tag="header"
      class="bg-mkm-yellow-default text-charcoal-default h-2xl md:h-[104px] w-full px-xs sm:px-sm relative"
      align="center"
      justify="between"
      gap="xs"
      :breakpoints="{ gap: { xs: 'sm', md: 'lg' } }"
    >
      <HeaderLeft />
      <InstantSearch />
      <HeaderRight :advice-links="adviceLinks" />
    </Stack>
    <PersonalisedNav />
  </div>
</template>

<script lang="ts" setup>
import type { Component, Page, Content } from "@bloomreach/spa-sdk";
import { excludedBranches } from "~/helpers/excludedBranches";
const props = defineProps<{ component: Component; page: Page }>();
const UKDefaultCoords = { lat: 54.5, lng: -4 };
const currentLocation = ref(UKDefaultCoords);
const isSearching = ref(false);
const { fetchNearestBranch } = useBranches();
const { component, page } = toRefs(props);
const { mount } = useSession();
const { selectedBranch, selectBranch } = useBranches();
const { setAutoBranchSelect } = useUIState();
const router = useRouter();

const { setRouterHistory } = useRouterHistory();

router.beforeEach((to, from, next) => {
  setRouterHistory(from.fullPath);

  next();
});

const nearestBranch = ref();

interface ContentWithModel extends Content {
  getItems: () => [];
}

const { menu: menuRef } = props.component.getModels();
const menu: ContentWithModel | undefined = props.page.getContent(menuRef) as ContentWithModel | undefined;

const adviceLinks = computed(() => {
  if (!menu) return;

  return menu
    ?.getItems()
    .filter((item: any) => item.getName() === "Advice")
    .flatMap((item: any) =>
      item.getChildren().map((child: any) => ({
        href: child.getUrl() as string,
        name: child.getName() as string,
      })),
    );
});

const getLocationFromBrowser = async () => {
  if (navigator.geolocation) {
    await nextTick(() => {
      isSearching.value = true;

      navigator.geolocation.getCurrentPosition(async (position) => {
        currentLocation.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        await nextTick();

        nearestBranch.value = await fetchNearestBranch({
          lat: currentLocation.value.lat,
          lng: currentLocation.value.lng,
          limit: 1,
          excludeBranchId: excludedBranches,
        });

        if (nearestBranch.value.length > 0) {
          await selectBranch(nearestBranch.value[0].id);
        }

        setAutoBranchSelect(true);

        isSearching.value = false;
      });
    });
  }
};

onMounted(async () => {
  await nextTick(async () => await mount());

  if (!selectedBranch.value) {
    await getLocationFromBrowser();
  }
});
</script>
